import React from "react"
import { useQuery } from "@apollo/react-hooks"
import renderHTML from "react-render-html"
import { FlexboxGrid, Col, Container, Header, Content } from "rsuite"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import Helmet from "react-helmet"

import GET_CONSEILS from "../queries/getConseils.graphql"

import groslogo from "../../static/logo_conseil.svg"

import TopHeader from "../components/topHeader"
import CustomMenu from "../components/custommenu"
import FooterSection from "../components/footer"
import SEO from "../components/seo"

import "react-accessible-accordion/dist/fancy-example.css"
import "../styles/conseils.css"

const ConseilsPage = () => {
  const { loading, error, data } = useQuery(GET_CONSEILS)
  if (loading) return null
  if (error) return `Error! ${error}`

  const { faqpageTitreTexte, faqpageQuestionReponse } = data.pageBy.acfFaq

  // Map questions and responses from the query
  const faqpageQuestionReponseData = faqpageQuestionReponse.map(item => {
    let data = {
      question: item.faqpageQuestion,
      reponse: item.faqpageReponse,
    }
    return data
  })

  // Create component and render the QR's
  const ListQR = qrs => (
    <Accordion allowZeroExpanded={true} allowMultipleExpanded={true}>
      {qrs.map((qr, i) => (
        <AccordionItem key={i}>
          <AccordionItemHeading>
            <AccordionItemButton>
              <div className="question">
                {qr.question && renderHTML(qr.question)}
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className="reponse">
              {qr.reponse && renderHTML(qr.reponse)}
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </Accordion>
  )

  // render
  return (
    <>
      <SEO
        title="Les Conseils d'Alimata"
        keywords={[`Les Conseils d'Alimata`]}
      />
      <Helmet>
        <title>Les Conseils d'Alimata</title>
        <meta name="description" content="Les Conseils d'Alimata" />
      </Helmet>
      <Container>
        <Header>
          <div className="conseils-header">
            <CustomMenu />
            <TopHeader />
            <FlexboxGrid justify="center" align="middle" className='customBlock'>
              <FlexboxGrid.Item componentClass={Col} colspan={24} md={6} xs={18}>
                <div className="img-header">
                  <img className="logo" src={groslogo} alt="logo" />
                </div>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </div>
        </Header>
        <Content id="page-wrap">
          <div className="content">
            <FlexboxGrid justify="center">
              <FlexboxGrid.Item
                componentClass={Col}
                colspan={24}
                md={18}
                xs={24}
              >
                {faqpageTitreTexte && faqpageTitreTexte[0].faqpageTitre && (
                  <h2 className="conseils-titre">
                    {faqpageTitreTexte[0].faqpageTitre}
                  </h2>
                )}
                <div className="conseils-texte">
                  {faqpageTitreTexte &&
                    faqpageTitreTexte[0].faqpageTexte &&
                    renderHTML(faqpageTitreTexte[0].faqpageTexte)}
                </div>
              </FlexboxGrid.Item>
            </FlexboxGrid>
            <FlexboxGrid justify="center" className="wrap-serie">
              <FlexboxGrid.Item
                componentClass={Col}
                colspan={24}
                md={16}
                xs={24}
              >
                {ListQR(faqpageQuestionReponseData)}
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </div>
        </Content>
        <FooterSection />
      </Container>
    </>
  )
}

export default ConseilsPage
